import React, { useState } from "react";
import { pdf, Document, Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { Spin } from "antd";
// import * from '../../../../public/img/logo.png'

const DocumentPdf = ({ title, activeFilters, startDate, endDate, metrics }) => {
  const start = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : moment().subtract(7, "d").format("YYYY-MM-DD");
  const end = endDate ? moment(endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  return (
    <Document title={title}>
      <Page style={styles.body}>
        <Image style={styles.logo} src={process.env.PUBLIC_URL + "/img/logo.png"} />
        <Text style={styles.title}>{title}</Text>
        <View style={styles.filters}>
          <View style={[styles.filter, activeFilters.includes("awareness") && styles.activeFilter]}>
            <Text style={styles.filterText}>Awareness</Text>
          </View>
          <View
            style={[styles.filter, activeFilters.includes("conversion") && styles.activeFilter]}
          >
            <Text style={styles.filterText}>Conversion</Text>
          </View>
          <View style={[styles.filter, activeFilters.includes("follows") && styles.activeFilter]}>
            <Text style={styles.filterText}>Follows</Text>
          </View>
        </View>
        <View style={styles.dateRange}>
          <Text style={styles.title}>Date range</Text>
          <Text style={styles.dates}>
            {start} - {end}
          </Text>
        </View>
        <View style={styles.metrics}>
          <Text style={styles.title}>Metrics</Text>
          <View style={styles.tile}>
            {metrics.slice(0, 6).map((item, i) => {
              return (
                <View key={i} style={styles.tileItemWrapper}>
                  <Text style={styles.tileItemValue}>
                    {item?.moneyIndicator ? `$${item.value.toFixed(2)}` : item.value}
                  </Text>
                  <Text style={styles.tileItemLabel}>{item.label}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.tile}>
            {metrics.slice(6, 12).map((item, i) => {
              return (
                <View key={i} style={styles.tileItemWrapper}>
                  <Text style={styles.tileItemValue}>
                    {item?.moneyIndicator ? `$${item.value.toFixed(2)}` : item.value}
                  </Text>
                  <Text style={styles.tileItemLabel}>{item.label}</Text>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

async function getProps(props) {
  await delay(1000);
  return {
    ...props,
  };
}

export const LazyDownloadPDFButton = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <button
      className={props.className}
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        const customProps = await getProps(props);
        const doc = <DocumentPdf {...customProps} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.setAttribute("href", fileURL);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      }}
    >
      {loading ? <Spin size="small" /> : "Download"}
    </button>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10,
    backgroundColor: "#443877",
  },
  logo: {
    width: 110,
    height: 43,
  },
  title: {
    color: "#ffffff",
    textAlign: "center",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    marginTop: "20px",
  },
  filter: {
    backgroundColor: "transparent",
    border: "1px solid transparent",
    borderRadius: "100px",
    padding: 5,
    width: "100px",
  },
  activeFilter: {
    borderColor: "#bd4581",
    backgroundColor: "#302854",
  },
  filterText: {
    color: "#ffffff",
    fontSize: 12,
    lineHight: 14,
    fontWeight: 700,
    textAlign: "center",
  },
  dateRange: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
  dates: {
    marginTop: "5px",
    color: "#ffffff",
  },
  metrics: {
    marginTop: "15px",
  },
  tile: {
    padding: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    marginTop: "10px",
  },
  tileItemWrapper: {
    padding: "5px",
    border: "1px solid #ffffff",
    borderRadius: 5,
    flex: 1,
  },
  tileItemValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#ffffff",
    fontSize: 14,
    fontWeight: 600,
  },

  tileItemLabel: {
    fontSize: 8,
    fontWeight: 400,
    color: "#ffffff",
    marginTop: "5px",
  },
});
