import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Animated } from "react-animated-css";
import { notification, Card, Space, Typography, List, Row, Col, Spin, Button } from "antd";
import { getCreatorsRequest, getCreatorsReset } from "../../redux/actions/list-creator";
import { toggleFavoriteRequest, toggleFavoriteReset } from "../../redux/actions/marketer-favorite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassChart, faEye, faHeart, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { faHeart as outlinedHeart } from "@fortawesome/pro-regular-svg-icons";
import CheckWeb from "../../components/CommonWidgets/CheckWeb";
import { formatNumber, checkIfUserLoggedIn } from "../../helper";
import "./index.scss";
import FindCreatorModal from "../../components/FindCreatorModal/index";
import AntSpinner from "../../components/CommonWidgets/AntSpinner";
import { CACHEVARIABLES } from "../../constants";
import NoDataFound from "../../components/Result/NoDataFound";
import Tags from "../../components/CommonWidgets/Tags";
import ImageRenderer from "../../components/CommonWidgets/ImageRenderer";
import FindCreatorSearch from "../../components/FindCreatorSearch/index";
import Colors from "../../theme/Colors";
import InstantBookOffer from "../../components/InstantBookOffer";
import AuthModal from "../../components/AuthModal";
const ls = require("localstorage-ttl");
const cacheTime = 1000 * 60 * 5;

// @ts-ignore

const { Title, Text } = Typography;

const FindCreators = (props) => {
  const web = CheckWeb(),
    [isLoading, setLoading] = useState(true),
    [showAuthModal, setShowAuthModal] = useState(false),
    creatorCacheVariable = CACHEVARIABLES.FIND_CREATORS_CACHE,
    dispatch = useDispatch(),
    isDataExist = ls.get(creatorCacheVariable),
    { listCreatorData, toggleFavoriteData } = useSelector((state) => ({ listCreatorData: state.listCreatorData, toggleFavoriteData: state.toggleFavoriteData })),
    [creatorList, setCreatorList] = useState(isDataExist),
    [masterCreatorList, setMasterCreatorList] = useState(isDataExist),
    [creatorTags, setCreatorTags] = useState(false),
    onSearch = (searchFields) => {
      let filteredData = masterCreatorList;

      if (searchFields?.userName) {
        filteredData = filteredData && filteredData.filter((items) => items.tiktokHandle.toLowerCase().includes(searchFields?.userName?.toLowerCase()));
        //   filteredData = filteredData && filteredData.filter((items) => items.tiktokHandle.includes(searchFields?.userName));
      }

      if (searchFields?.tags?.length > 0) {
        filteredData = filteredData && filteredData.filter((items) => items.tags && items.tags.some((item) => searchFields?.tags.includes(item.trim())));
      }
      if (searchFields?.averageViewsFrom) {
        filteredData = filteredData && filteredData.filter((items) => searchFields?.averageViewsFrom < items?.tiktokInfo?.actualAverageViews);
      }
      if (searchFields?.averageViewsTo) {
        filteredData = filteredData && filteredData.filter((items) => searchFields?.averageViewsTo > items?.tiktokInfo?.actualAverageViews);
      }
      if (searchFields?.followersFrom) {
        filteredData = filteredData && filteredData.filter((items) => searchFields?.followersFrom < items?.tiktokInfo?.followers);
      }
      if (searchFields?.followersTo) {
        filteredData = filteredData && filteredData.filter((items) => searchFields?.followersTo > items?.tiktokInfo?.followers);
      }

      if (searchFields?.costFrom) {
        filteredData = filteredData && filteredData.filter((items) => searchFields?.costFrom < items?.tiktokInfo?.price);
      }
      if (searchFields?.costTo) {
        filteredData = filteredData && filteredData.filter((items) => searchFields?.costTo > items?.tiktokInfo?.price);
      }
      if (searchFields?.engagementRate) {
        filteredData = filteredData && filteredData.filter((items) => parseFloat(items?.tiktokInfo?.engagementRate) >= parseFloat(searchFields?.engagementRate));
      }
      if (searchFields?.isFavorite) {
        filteredData = filteredData && filteredData.filter((items) => items.isFavorite === searchFields?.isFavorite);
      }
      console.log(searchFields);
      setCreatorList(filteredData);
    },
    [showFindCreatorModal, setShowFindCreatorModal] = useState(false),
    [selectedCreator, setSelectedCreator] = useState({}),
    showOfferWizard = (item) => {
      if (!checkIfUserLoggedIn()?.userType) {
        setShowAuthModal(true);
      } else {
        setSelectedCreator(item);
        setShowFindCreatorModal(true);
      }
    },
    togglefavorite = (e, id) => {
      e.stopPropagation();
      setSelectedCreator(id);
      dispatch(toggleFavoriteRequest(id));
    };

  useEffect(() => {
    setMasterCreatorList(masterCreatorList);
  }, [masterCreatorList]);

  useEffect(() => {
    /* if (isDataExist) {
      setLoading(false);
      return;
    } */
    setLoading(true);
    dispatch(getCreatorsRequest());
  }, []);

  useEffect(() => {
    if (creatorList != null && isLoading) {
      setLoading(false);
    }
  }, [creatorList]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    //  e.preventDefault();
    //e.returnValue = "";
    dispatch(getCreatorsRequest());
  };

  useEffect(() => {
    if (!listCreatorData.error && Object.keys(listCreatorData?.payload).length) {
      const creatorsList = listCreatorData?.payload?.data;
      setCreatorList(creatorsList);
      setMasterCreatorList(creatorsList);
      ls.set(creatorCacheVariable, listCreatorData?.payload?.data, cacheTime);

      const listTags = creatorsList.filter((row) => row?.tags && row?.tags?.length > 0).map((filteredRow) => filteredRow?.tags);
      let finalListTags = listTags && listTags.flat(),
        trimTags = finalListTags.map((tag) => tag.trim()),
        uniqueTags = [...new Set(trimTags)];
      setCreatorTags(uniqueTags);
    } else {
      if (listCreatorData?.error?.message) {
        setLoading(false);
        dispatch(getCreatorsReset());
        console.log("listCreatorData?.error?.message", listCreatorData?.error?.message);
        notification.error({ message: "Error", description: listCreatorData?.error?.message, placement: "topRight" });
      }
    }
  }, [listCreatorData]);

  useEffect(() => {
    if (!toggleFavoriteData.error && Object.keys(toggleFavoriteData?.payload).length) {
      notification.success({ message: "Success", description: toggleFavoriteData?.payload?.message, placement: "topRight" });
      if (creatorList) {
        const index = creatorList.findIndex((item) => item._id === selectedCreator),
          list = [...creatorList];
        if (index != -1) {
          list[index]["isFavorite"] = toggleFavoriteData?.payload?.data?.newFav;
          setCreatorList(list);
          masterCreatorList[index].isFavorite = toggleFavoriteData?.payload?.data?.newFav;
          setMasterCreatorList(masterCreatorList);
          localStorage.removeItem(creatorCacheVariable);
          ls.set(creatorCacheVariable, list, cacheTime);
          // setCardLoading(false);
          dispatch(toggleFavoriteReset());
        }
      }
    } else {
      if (toggleFavoriteData?.error?.message) {
        //  setCardLoading(false);
        dispatch(toggleFavoriteReset());
        notification.error({ message: "Error", description: toggleFavoriteData?.error?.message, placement: "topRight" });
      }
    }
  }, [toggleFavoriteData]);

  useEffect(() => {
    setCreatorList(creatorList);
  }, [creatorList]);

  return (
    <>
      <InstantBookOffer location={props.location.search} />
      <Card className={"mt5"}>
        <Title level={1}>
          <span>
            <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Directly Book Creators
          </span>
          <Spin indicator={AntSpinner} spinning={isLoading} size={"large"} className={"creator-spin ml3"}></Spin>
        </Title>

        <FindCreatorSearch isSearchEnabled={!isLoading} creatorTags={creatorTags} startSearch={onSearch} />

        {creatorList?.length > 0 ? (
          <List
            className="mt5"
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 5,
              xl: 5,
              xxl: 5,
            }}
            dataSource={creatorList}
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 15,
              showSizeChanger: false,
            }}
            renderItem={(item, index) => (
              <Animated animationIn="fadeIn" isVisible={true} animationInDuration={600} animationInDelay={500 + (index + 1) * 100}>
                <List.Item key={item.key} className="w-full p0">
                  <Card key={`a${item.key}`} onClick={() => showOfferWizard(item)} className="find-creators" bordered={false}>
                    <Space direction="horizontal" className="w-full justify-content-between">
                      <div onClick={(e) => togglefavorite(e, item._id)}>
                        <FontAwesomeIcon icon={item?.isFavorite === true ? faHeart : outlinedHeart} size={"lg"} color={Colors.primaryColor} />
                      </div>
                      <div className="fw-500">{formatNumber(item?.tiktokInfo?.price)}</div>
                    </Space>
                    <div className={"text-center"}>
                      <ImageRenderer imageUrl={item?.tiktokInfo?.profileImage} isAvatar={false} className="profile-img mb2" />
                      <Title level={5} className={"find-handle"}>
                        {item?.tiktokHandle}
                      </Title>
                      {/** To DO: MOVE TO CSS FILE */}
                      <div className={"offer-card creator-tags"}>{item?.tags && item?.tags.length > 0 && <Tags tagsData={item?.tags} showItems={3} />}</div>
                    </div>
                    <div className="content-sec">
                      <div>
                        <p>
                          <FontAwesomeIcon icon={faHeart} color={Colors.lightText} /> <Text level={6}>{item?.tiktokInfo?.engagementRate}% Engagement</Text>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faUsers} color={Colors.lightText} /> <Text level={6}>{item?.tiktokInfo?.totalFollowers} Followers</Text>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faEye} color={Colors.lightText} /> <Text level={6}>{item?.tiktokInfo?.avgViews} Average Views</Text>
                        </p>
                      </div>
                    </div>
                  </Card>
                </List.Item>
              </Animated>
            )}
          />
        ) : (
          <>{!isLoading && <NoDataFound showContent={isLoading} />}</>
        )}
      </Card>
      {showFindCreatorModal === true && <FindCreatorModal open={true} close={() => setShowFindCreatorModal(false)} selectedCreator={selectedCreator} />}
      {showAuthModal && <AuthModal open={showAuthModal} close={() => setShowAuthModal(false)} />}
    </>
  );
};
export default FindCreators;
