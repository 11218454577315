import React, { useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { apiCall } from "../../../../modules/api-call";
import { CONFIG } from "../../../../config/config";
import { Spin, Select } from "antd";
import styles from "./index.module.scss";
import { METRICS_OPTIONS } from "../../../../constants";

const BASIC_OPTINS = {
  chart: {
    type: "pie",
    backgroundColor: "#382f61",
    className: styles.chartRoot,
  },
  tooltip: {},
  plotOptions: {
    series: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: [
        {
          enabled: true,
          distance: 20,
        },
        {
          enabled: true,
          distance: -40,
          format: "{point.percentage:.1f}%",
          style: {
            fontSize: "20px",
            textOutline: "none",
          },
          filter: {
            operator: ">",
            property: "percentage",
            value: 10,
          },
        },
      ],
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      type: "pie",
      name: "Random data",
      data: [],
    },
  ],
  lang: {
    noData: "Nessun dato presente",
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#303030",
    },
  },
};

const aggregateData = (data, selectedMetric, filterKey) => {
  return data.reduce((acc, curr) => {
    const country = curr.dimensions[filterKey];
    const value = parseFloat(curr.metrics[selectedMetric]);
    if (acc[country]) {
      acc[country] += value;
    } else {
      acc[country] = value;
    }

    return acc;
  }, {});
};

const Demographic = ({ boostId, startDate, endDate, isOpen }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ data: [], empty: false });
  const [filter, setFilter] = useState("country");
  const [selectedMetric, setSelectedMetric] = useState("impressions");

  const getMetricsByFilter = async (filter) => {
    setLoading(true);
    let url =
      CONFIG.BACKEND_API_URL +
      `user/evergreeen-boost/demographic?boostId=${boostId}&filter=${filter}`;
    if (startDate && endDate) {
      url = url + `&startDate=${startDate}&endDate=${endDate}`;
    }
    try {
      const { data: response } = await apiCall({
        method: "GET",
        url,
        auth: 1,
      });

      setData({
        data: response?.data || null,
        empty: response.error && response.statusCode === 200,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const piechartOptions = useMemo(() => {
    if (data.empty) {
      const pieChartOptions = {
        ...BASIC_OPTINS,
        title: {
          className: styles.hightChartsTitle,
          text: "No data for selected period",
          style: {
            color: "#ffffff",
          },
        },
        series: [{ name: "Impressions", colorByPoint: true, data: [] }],
      };
      return pieChartOptions;
    } else {
      const filterKey = filter === "country" ? "country_code" : "gender";
      const aggregatedData = aggregateData(data.data, selectedMetric, filterKey);
      const seriesData = Object.entries(aggregatedData).map(([country, value]) => ({
        name: country,
        y: value,
      }));
      const pieChartOptions = {
        ...BASIC_OPTINS,
        title: {
          className: styles.hightChartsTitle,
          align: "left",
          text: "Browser Infinite Growth result",
          style: {
            color: "#ffffff",
            marginLeft: "20px",
          },
        },
        subtitle: {
          className: styles.highchartsSubtitle,
          text: "Click the slices to view versions",
          align: "left",
          style: {
            color: "#ffffff",
          },
        },
        series: [
          {
            name: METRICS_OPTIONS.find(({ value }) => value === selectedMetric).label,
            colorByPoint: true,
            data: seriesData,
          },
        ],
      };
      return pieChartOptions;
    }
  }, [data, selectedMetric, filter]);

  useEffect(() => {
    isOpen && getMetricsByFilter(filter);
  }, [isOpen, filter]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>Who did we reach?</p>
        <div className={styles.filtersWrapper}>
          <div className={styles.filters}>
            <button
              onClick={() => setFilter("country")}
              className={`${styles.button} ${filter === "country" ? styles.active : null}`}
            >
              Countries
            </button>
            <button
              onClick={() => setFilter("gender")}
              className={`${styles.button} ${filter === "gender" ? styles.active : null}`}
            >
              Male/Female
            </button>
          </div>
          <div className={styles.metricsSelectWrapper}>
            <Select
              className={styles.selector}
              onChange={(value) => setSelectedMetric(value)}
              value={selectedMetric}
              options={METRICS_OPTIONS}
              placeholder="Metrics"
            />
          </div>
        </div>
      </div>
      <div className={styles.pieChartWrapper}>
        {loading ? (
          <div className={styles.loaderWrapper}>
            <Spin size="large" />
          </div>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={piechartOptions}></HighchartsReact>
        )}
      </div>
    </div>
  );
};

export default Demographic;
