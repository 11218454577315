import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Typography, Button, notification, Input, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCommentAlt, faShareAlt, faCircleCheck, faMessage, faMagnifyingGlassChart, faXmark } from "@fortawesome/pro-solid-svg-icons";
import "../assets/styles/modal.scss";
import FindCreatorOfferWizard from "../FindCreatorOfferWizard";
import { numberFormatter, formatNumber, checkIfUserLoggedIn, trimSpace } from "../../helper";
import { createChannel } from "../../modules/pubnub-module";
import Tags from "../CommonWidgets/Tags";
import ModalHeader from "../ModalHeader";
import CheckWeb from "../CommonWidgets/CheckWeb";
import { faChartArea } from "@fortawesome/pro-regular-svg-icons";
import { LeftArrow, RightArrow } from "../CommonWidgets/FontIcons";
import useAnalytics from "../../hooks/useAnalytics";
import "./index.scss";
const { Title, Text } = Typography;

const FindCreatorModal = (props) => {
  const [currentModalStep, setCurrentModalStep] = useState(-1),
  {trackDirectBookOrderInterrupted} = useAnalytics(),
    [createOfferNextStatus, setCreateOfferNextStatus] = useState(true),
    [createCardNextStatus, setCreateCardNextStatus] = useState(true),
    [bookStep, setBookStep] = useState(0),
    web = CheckWeb(),
    bookOfferRef = useRef(),
    history = useHistory(),
    data = [
      {
        key: "1",
        title: "Projected Views",
        value: props?.selectedCreator?.tiktokInfo.predictedViews,
        icon: "",
      },
      {
        key: "2",
        title: "Price",
        value: formatNumber(props?.selectedCreator?.tiktokInfo?.price),
        icon: "",
      },
      {
        key: "3",
        title: "Avg. Views",
        value: props?.selectedCreator?.tiktokInfo?.avgViews,
        icon: faEye,
      },
      {
        key: "4",
        title: "Avg. Comments",
        value: props?.selectedCreator?.tiktokInfo?.avgComments,
        icon: faCommentAlt,
      },
      {
        key: "5",
        title: "Avg. Shares",
        value: props?.selectedCreator?.tiktokInfo?.avgShares,
        icon: faShareAlt,
      },
      {
        key: "6",
        title: "Views",
        value: numberFormatter(props?.selectedCreator?.tiktokInfo?.totalViews),
        icon: faEye,
      },
      {
        key: "7",
        title: "Engagement",
        value: props?.selectedCreator?.tiktokInfo.engagementRate + "%",
        icon: faChartArea,
      },
    ],
    newBookStep = (newStep) => {
      setBookStep(newStep);
    },
    trackBookingInterruptStep = () => {
      let stepText = "Direct Campaign - Create"
      switch (bookStep) {
        case 1:
        stepText = "Direct Campaign - Payment";
        break;
        case 2:
        stepText = "Direct Campaign - Confirmation";
        break;
        default:
          break;
      }
      trackDirectBookOrderInterrupted(stepText);
      return true;
    };

  useEffect(() => {
    setCurrentModalStep(currentModalStep);
  }, [currentModalStep]);

  return (
    <Modal
      open={props.open}
      mask={true}
      bodyStyle={{ height: "var(--find-creator-modal-height)" }}
      title={<ModalHeader titleIcon={faMagnifyingGlassChart} modalTitle={"Book Creator"} close={props.close} />}
      className="find-creator-modal"
      width={1200}
      centered
      onCancel={()=>{trackBookingInterruptStep(); props.close}}
      closable={false}
      footer={
        currentModalStep === -1 ? (
          <>
            <Button type="default" onClick={() => props.close()}>
                  <LeftArrow /> Back
                </Button>
            <Button type={"primary"} onClick={() => setCurrentModalStep(0)}>
              <FontAwesomeIcon icon={faCircleCheck} /> Book Creator
            </Button>
          </>
        ) : (
          <>
            {bookStep < 1 && (
              <>
                <Button type="default" onClick={() => setCurrentModalStep(-1)}>
                  <LeftArrow /> Back
                </Button>
                <Button type="primary" onClick={() => bookOfferRef?.current?.onNext()} disabled={createOfferNextStatus}>
                  Next <RightArrow />
                </Button>
              </>
            )}
            {bookStep >= 1 && bookStep < 2 && (
              <>
                <Button type="default" onClick={() => bookOfferRef?.current?.onPrev()}>
                  <LeftArrow /> Cancel
                </Button>
                <Button type="primary" onClick={() => bookOfferRef?.current?.onMoveToNext()} disabled={createCardNextStatus}>
                  Next <RightArrow />
                </Button>
              </>
            )}

            {bookStep === 2 && (
              <>
                <Button type="default" onClick={() => bookOfferRef.current.onPrev()}>
                  <LeftArrow /> Back
                </Button>
                <Button type="primary" onClick={() => bookOfferRef?.current?.onBookCreateOffer()} loading={bookOfferRef.current.onLoading()}>
                  <FontAwesomeIcon icon={faCircleCheck} /> Book Creator
                </Button>
              </>
            )}
          </>
        )
      }
    >
      <div className="flex flex-column w-full">
        {currentModalStep === -1 ? (
          <>
            <iframe title="TikTok" className="tt-iframe" src={`https://www.tiktok.com/embed/${props.selectedCreator.tiktokHandle[0] === "@" ? "" : "@"}${props.selectedCreator.tiktokHandle}`} scrolling="no" allow="encrypted-media;" style={{ backgroundColor: "#fff", width: "100%", height: web ? 500 : 400, borderRadius: "8px 8px 0px 0px", border: "none", margin: 0, padding: 0 }}>
              <div className="frame-container"></div>
            </iframe>
            <div className="creator-description">
              <div className="tags-container">{props?.selectedCreator?.tags && props?.selectedCreator?.tags.length > 0 && <Tags tagsData={props?.selectedCreator?.tags} showItems={props?.selectedCreator?.tags && props?.selectedCreator?.tags.length} />}</div>
            </div>
            <div className="info-container">
              {data.map((item, index) => {
                return index <= 1 ? (
                  <Card className="info-item" key={item?.value}>
                    <p>
                      {item.icon && <FontAwesomeIcon className="ico" icon={item.icon} />} <Title className="value">{item?.value}</Title>
                    </p>
                    <Text className="title">{item?.title}</Text>
                  </Card>
                ) : null;
              })}
            </div>

            <Card>
              <div className="view-info-container">
                {data.map((item, index) => {
                  return index > 1 ? (
                  
                    <div className="view-info-item" key={item?.value}>
                      <p>
                        {item.icon && <FontAwesomeIcon icon={item.icon} />} {item?.value}
                      </p>
                      <h5>{item?.title}</h5>
                    </div>
               
                  ) : null;
                })}
              </div>
            </Card>
          </>
        ) : (
          <FindCreatorOfferWizard wOpen={true} ref={bookOfferRef} wClose={props.close} selectedCreator={props.selectedCreator} updateCurrentBookStep={(step) => newBookStep(step)} updateCreateOfferNextStatus={(status) => setCreateOfferNextStatus(status)} currentOfferNextButtonStatus={createOfferNextStatus} updateCreateCardNextStatus={(status) => setCreateCardNextStatus(status)} currentCardNextButtonStatus={createCardNextStatus} />
        )}
      </div>
    </Modal>
  );
};

export default FindCreatorModal;
